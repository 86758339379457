import React, { useEffect, useState } from "react";
import { ThemeSubscription } from "../helpers/theme";
import { Icon } from "./icons";

export const Layout = ({ children }: { children: JSX.Element }) => {
  const [isDark, setIsDark] = useState(true);

  useEffect(() => {
    ThemeSubscription.subscribe(setIsDark);
  }, []);

  return (
    <>
      <div
        className="header"
        style={{
          display: "flex",
          flexDirection: "row",
          padding: 8,
          alignItems: "flex-start",
        }}
      >
        <div style={{ fontSize: 36, marginRight: 16 }}>
          <a href="/">OpenRarity</a>
        </div>
        <div style={{ fontSize: 20 }}>
          <a target="_blank" href="https://github.com/TENK-DAO/tenk/discussions">
            <Icon icon="github" />
          </a>
        </div>
        <div style={{ flex: 1 }}></div>
      </div>
      <div>{children}</div>
    </>
  );
};
