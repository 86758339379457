import { NftProjectsLoader } from "./nft-projects";

export const HomePage = () => {
  return (
    <div
      style={{
        display: "flex",
        flexDirection: "column",
        justifyContent: "space-between",
        alignItems: "center",
        minHeight: "calc(100vh - 100px)",
      }}
    >
      <div style={{ width: "100%" }}>
        <NftProjectsLoader />
      </div>
      <div>
        <div style={{ margin: "10px" }}>
            <a target="_open" href="https://tenkbay.com">
            <img
                style={{ width: 100, height: 100, objectFit: "contain" }}
                src={`/media/tenk.jpg`}
                alt="punk"
            />
            </a>
        </div>
      </div>
    </div>
  );
};
